<template>
    <div>
        <!-- Notification Bar -->
        <NotificationBar :notifications="notifications" />

        <v-toolbar
            class="page-heading-bar"
            color="blue-grey lighten-5"
        >
            <v-toolbar-title>
                <h1 class="text-body-1 text-sm-h5 text-md-h4 page-heading-bar__title">
                    {{ pageHeading }}
                </h1>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- Back Button -->
            <v-btn
                v-if="backArrow"
                color="primary"
                dark
                @click="goBack()"
            >
                Back to Search
            </v-btn>
        </v-toolbar>

        <v-progress-linear
            v-if="loading"
            indeterminate
            class="page-heading-bar__loader"
        />
    </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Components
import NotificationBar from '@/components/NotificationBar';

export default {
    name: 'ThePageHeadingBar',
    components: {
        NotificationBar,
    },
    props: {
        pageHeading: {
            type: String,
            default: 'Oops, no pageHeading defined in data() on this page',
            required: false,
        },
        fullSize: {
            type: Boolean,
            required: false,
            default: false,
        },
        backArrow: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        ...mapState( {
            notifications: ( state ) => state.notifications,
            modifyTitleInFlight: ( state ) => state.Title.inFlight,
            catalogListingInFlight: ( state ) => state.CatalogListing.inFlight,
            queueLoading: ( state ) => state.Title.Queue.inFlight,
        } ),
        loading() {
            return this.modifyTitleInFlight || this.catalogListingInFlight || this.queueLoading;
        },
    },
    methods: {
        goBack() {
            this.$emit( 'goBack' );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/pageheader.scss";

.page-heading-bar {
    &__title {
        color: $dark-gray;
        max-width: 50vw;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__loader {
        ::v-deep {
            .v-progress-linear__background {
                left: unset !important;
                width: 100% !important;
            }
        }
    }
}
</style>
