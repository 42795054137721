<template>
    <div
        id="sidebar-container"
        class="box-shadow"
    >
        <div class="sidebar-content">
            <!-- Hamburger Button -->
            <nav>
                <v-btn
                    icon
                    dark
                    xLarge
                    class="navbar-button"
                    @click="toggleSidebar()"
                >
                    <v-icon>
                        mdi-menu
                    </v-icon>
                </v-btn>
            </nav>
            <!-- Slot -->
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'TheSidebar',
    methods: {
        ...mapActions( {
            toggleSidebar: 'Navigation/toggleDrawer',
        } ),
    },
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables.scss";
    @import "@/assets/sass/sidebar.scss";

    #sidebar-container {
        grid-area: sidebar;
        z-index: 10;
        text-align: center;
        background-color: $sidebar-container-bg;
        display: flex;
        flex-direction: column;
        color: $sidebar-container-fg;
    }

    nav {
        text-align: start;
    }

    .sidebar-content {
        align-self: flex-start;
        width: 100%;

        .navbar-button {
            margin: .5rem;
        }
    }

</style>
