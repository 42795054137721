<script>
import { mapActions } from 'vuex';

export default {
    name: 'NotificationBar',
    props: {
        notifications: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        hasNotification() {
            return !!this.notifications.length;
        },
    },
    methods: mapActions( {
        removeNotification: 'removeNotification',
    } ),
};
</script>

<template>
        <div class="notification-container">
            <div
                v-for="(notification, index) in notifications"
                :key="`${notification.type}-${index}`"
                class="notification-background"
            >
                <v-alert
                    v-model="hasNotification"
                    :type="notification.type"
                    dismissible

                    border="left"
                >
                    <div v-html="notification.message"/>
                    <template #close>
                        <v-btn
                            icon
                            small
                            class="ml-4"
                            @click="removeNotification( index )"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </template>
                </v-alert>
            </div>
        </div>
</template>

<style lang="scss" scoped>
.notification-container {
    position: fixed;
    max-width: 600px;
    top: 10px;
    right: 10px;
    z-index: 999;
    .notification-background {
        background-color: #ffffff;
        border-radius: 5px;
    }
}
</style>
